import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		province_id: 1532,
		city_id: 1533,
		area_id: 1544
	},

	mutations: {
		editcity(state, val) {
			state.city_id = val
		},
		editarea(state, val) {
			state.area_id = val
		},
	},
	actions: {},
	modules: {}
})